import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Select,
  Space,
  Table,
  Form,
  Input,
  message,
} from 'antd';
import {
  FormData as FormDataEntity,
  FormDataCount as FormDataCountEntity,
} from 'lib/entities';
import { useGlobalState, useGet, usePost } from '@rlean/core';
import { pages } from 'config';
import { useActivePage } from 'lib/hooks';
import { SearchOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { dateFormatter } from 'lib/helpers/dateFormater';

const { RangePicker } = DatePicker;

/**
 * Basic version of the filter state/hooks from Engage
 * @param {string} title For placeholder
 * @param {Function} onSearch callback to main component
 */
const FilterDropdown = ({ title, onSearch }) => {
  const [value, setValue] = useState('');

  // Execute callback
  const handleOnSearch = () => {
    onSearch(value);
  };

  const handleReset = () => {
    onSearch();
    setValue('');
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search by ${title}`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={handleOnSearch}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <div>
        <Button
          type='primary'
          onClick={handleOnSearch}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 10 }}
        >
          Search
        </Button>
        <Button onClick={handleReset} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    </div>
  );
};

/**
 * Form Data conponent
 */
const FormData = () => {
  const [{ userRoles }] = useGlobalState();

  useActivePage(pages.FORM_DATA);

  // State for data
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [formsSubmitted, setFormsSubmitted] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchDisabled, setSearchDisabled] = useState(true);

  // Forms refs for search and for apply action
  const [searchForm] = Form.useForm();
  const [applyActionForm] = Form.useForm();

  const [get] = useGet();
  const [post] = usePost();

  const [loadingGet, setLoadingGet] = useState(false);
  const [loadingCountGet, setLoadingCountGet] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);

  useEffect(() => {
    if (
      pages.FORM_DATA.roles &&
      !userRoles.roles.some((role) => pages.FORM_DATA.roles.includes(role))
    ) {
      navigate('/home');
    }
  }, []);

  const getData = (values, entity, callback) => {
    return new Promise((resolve, reject) => {
      const { projectId, statusId, dates, formDataId } = values;
      const params = {
        ...(dates
          ? {
              startDate: dates[0].format('MM/DD/YYYY'),
              endDate: dates[1].format('MM/DD/YYYY'),
            }
          : {}),
        ...(projectId ? { projectId } : {}),
        ...(statusId ? { statusId } : {}),
        ...(formDataId ? { formDataId } : {}),
      };
      get(
        {
          entity,
          params,
        },
        (res, err) => {
          if (!err) {
            callback(res.data);
            resolve();
          } else {
            message.error("There's an error, couldn't get data");
            console.error(err);
            reject();
          }
        }
      );
    });
  };

  // Get data from endpoint using searchForm values
  const getTableData = async (values) => {
    setLoadingGet(true);
    await getData(values, FormDataEntity, (data) => {
      setData(data.map((i) => ({ ...i, key: i.formDataId })));
      setDataCount(data.length);
    });
    setLoadingGet(false);
  };

  // Get count data from endpoint using searchForm values
  const getDataCount = async () => {
    setLoadingCountGet(true);
    setData([]); // Remove data from table if data count is being called again
    const values = searchForm.getFieldsValue();
    await getData(values, FormDataCountEntity, (data) => {
      setDataCount(data);
    });
    setLoadingCountGet(false);
  };

  // Post action using applyActionForm values
  const updateFormSubmissions = (values) => {
    setLoadingApply(true);
    const { actionId } = values;
    post(
      {
        entity: FormDataEntity,
        body: { actionId: parseInt(actionId), formDataIds: selectedItems },
      },
      (_, err) => {
        if (!err) {
          message.success('Submitted. Search again to see changes.');
          setFormsSubmitted((pV) => [...pV, ...selectedItems]);
          setSelectedItems([]);
        } else {
          message.error('Something went wrong.');
          console.error(err);
        }
        setLoadingApply(false);
      }
    );
  };

  const updateFilters = (key, value) => {
    if (value) {
      setFilters((pV) => ({ ...pV, [key]: value }));
    } else {
      setFilters((pV) => {
        const { [key]: _, ...allExceptKey } = filters;
        return allExceptKey;
      });
    }
  };

  const onSearchValuesChange = (
    _,
    { dates, formDataId, projectId, statusId }
  ) => {
    if (formDataId || projectId || statusId) {
      setSearchDisabled(false);
    } else {
      setSearchDisabled(true);
    }
  };

  // Function for table selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedItems([...newSelectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys: selectedItems,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: formsSubmitted.includes(record.formDataId),
      name: `chx-${record.formDataId}`,
    }),
  };

  const columns = [
    {
      title: 'Project ID',
      key: 'projectId',
      dataIndex: 'projectId',
      fixed: 'left',
      filterDropdown: () => (
        <FilterDropdown
          title='Project ID'
          onSearch={(value) => updateFilters('projectId', value)}
        />
      ),
      filterIcon: (
        <SearchOutlined
          style={{ color: filters['projectId'] ? '#1890ff' : undefined }}
        />
      ),
    },
    {
      title: 'Form Data ID',
      key: 'formDataId',
      dataIndex: 'formDataId',
      fixed: 'left',
      filterDropdown: () => (
        <FilterDropdown
          title='Form Data ID'
          onSearch={(value) => updateFilters('formDataId', value)}
        />
      ),
      filterIcon: (
        <SearchOutlined
          style={{ color: filters['formDataId'] ? '#1890ff' : undefined }}
        />
      ),
    },
    {
      title: 'Status',
      key: 'statusName',
      dataIndex: 'statusName',
      fixed: 'left',
    },
    {
      title: 'Archived',
      key: 'isArchived',
      dataIndex: 'isArchived',
      fixed: 'left',
      render: (bool) => (bool ? 'Yes' : 'No'),
    },
    {
      title: 'Status at',
      key: 'statusAt',
      dataIndex: 'statusAt',
      fixed: 'left',
      render: (date) => {
        return dateFormatter(new Date(date));
      },
      sorter: (a, b) => {
        return new Date(a.statusAt) - new Date(b.statusAt);
      },
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      fixed: 'left',
      render: (date) => {
        return dateFormatter(new Date(date));
      },
      sorter: (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
    },
    {
      title: 'Modified By',
      key: 'modifiedBy',
      dataIndex: 'modifiedBy',
      fixed: 'left',
    },
  ];

  // Filter data by Project or Form if any
  const filteredData = data.filter((record) =>
    Object.keys(filters).reduce((pV, filterKey) => {
      if (typeof record[filterKey] === 'number') {
        return (
          pV && record[filterKey].toString().startsWith(filters[filterKey])
        );
      } else {
        return pV && record[filterKey].startsWith(filters[filterKey]);
      }
    }, true)
  );

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <h1>Search Form Data</h1>
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 8 }}
        form={searchForm}
        onFinish={getTableData}
        onValuesChange={onSearchValuesChange}
      >
        <Form.Item label='Project ID' name='projectId'>
          <Input />
        </Form.Item>
        <Form.Item label='Form Data ID' name='formDataId'>
          <Input />
        </Form.Item>
        <Form.Item label='Status' name='statusId'>
          <Select allowClear>
            <Select.Option value='9'>Discard</Select.Option>
            <Select.Option value='10'>Discarded</Select.Option>
            <Select.Option value='12'>Discard received by Azure</Select.Option>
            <Select.Option value='5'>Failure</Select.Option>
            <Select.Option value='11'>Failure discarding</Select.Option>
            <Select.Option value='8'>Failure sending to Backend</Select.Option>
            <Select.Option value='3'>Received by Azure</Select.Option>
            <Select.Option value='4'>Saved to Engage</Select.Option>
            <Select.Option value='2'>Submitted</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label='Created in Date Range' name='dates'>
          <RangePicker />
        </Form.Item>
        <Space direction='horizontal'>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loadingGet}
              disabled={searchDisabled}
            >
              Search
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='button'
              onClick={getDataCount}
              loading={loadingCountGet}
              disabled={searchDisabled}
            >
              Get Results Count
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <h1>Data count: {dataCount ?? '--'}</h1>
      <h1>
        Update selected
        {selectedItems.length === 0
          ? ' - No selections'
          : ` - ${selectedItems.length} selected`}
      </h1>
      <Table
        bordered
        loading={loadingGet}
        rowSelection={rowSelection}
        size='small'
        dataSource={filteredData}
        columns={columns}
        pagination={{
          pageSize: 20,
          pageSizeOptions: ['20', '50', '100'],
          showSizeChanger: true,
        }}
      />
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 8 }}
        form={applyActionForm}
        onFinish={updateFormSubmissions}
      >
        <Form.Item label='Action' name='actionId'>
          <Select>
            <Select.Option value='1'>Resubmit</Select.Option>
            <Select.Option value='2'>Discard</Select.Option>
            <Select.Option value='3'>Unarchive</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            disabled={selectedItems.length === 0}
            loading={loadingApply}
          >
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default FormData;
